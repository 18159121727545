body {
    font-family: $font-family-primary !important;
}

.container {
  max-width: 1150px;
}

.nav-section-1 {
  padding: 27px 0;

  .navsec-1-items {
    ul {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 30px 0;
  }
}

.navsec-1-items {
  display: flex;
  flex-grow: 1;

  ul {
    margin-bottom: 0;
  }
}

.light-silver {
  background-color: $light-silver;
}

.nav-section-2 {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;

  .navsec-2-group-1 {
    flex-grow: 1;

    ul {
      display: flex;
      justify-content: center;
      flex-grow: 1;

      li {
        padding: 0 15px;
      }
    }
  }

  .navsec-2-group-2 {
    ul {
      display: flex;

      li {
        padding: 0 15px;
      }
    }
  }
}

.header-link-list li {
  font-size: 12px;
  color: #9a9a9a;
  text-transform: uppercase;

  &:hover {
    font-weight: bold;
    cursor: pointer;
    transition: 0.1s;
  }
}

.header-link-list-1 li {
  font-weight: normal;

  &:hover {
    color: #70cde4;
  }
}

.header-link-list-2 li {
  font-weight: 600;

  &:hover {
    color: #515151;
  }
}

.bg-blue-section {
  background-image: radial-gradient(circle at 9% 0, #009ad9, #0978a6);
}

.title-lg {
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  font-size: 50px;
  font-weight: bold;
  color: #ffffff;
  line-height: 58px;
}

  @media screen and (max-width: 767px) {
    .section-intro .title-lg {
      font-size: 32px;
      text-align: center;
      line-height: normal;
    }
  }

.title-md {
  font-size: 40px;
  font-weight: 300;
}

.title-discover {
  font-size: 60px;
  font-weight: 300;
  line-height: 1.16;
  z-index: 1;
  line-height: normal;

  &.shadow {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  }
  
  @media screen and (max-width: 767px) {
    font-size: 40px;
    line-height: 65px;
  }
}

.color-light {
  color: #ffffff;
}
.color-blue {
  color: #0099cc;
}
.color-dark-1 {
  color: #363636;
}
.color-dark-2 {
  color: #515151;
}
.regular-text {
  font-size: 20px;
  font-weight: 300;
  line-height: 1.6;
  margin: 20px 0;

  @media screen and (max-width: 767px) {
    text-align: center;
    margin: 35px 0;
  }
  @media screen and (max-width: 375px) {
    margin: 10px 0;
  }
}

.button {
  text-transform: uppercase;
  font-size: 18px;
  cursor: pointer;
  display: inline-block;
}
.btn-orange {
  border-radius: 4px;
  box-shadow: 0 28px 15px -22px rgba(0, 0, 0, 0.3);
  background-color: #e98400;
  color: #ffffff;
  padding: 24px 71px 24px 18px;
  margin-top: 10px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  line-height: normal;

  img {
    right: 18px;
    position: absolute;
  }
}

.rotate-sun, .scale-smile, .play-btn-icon {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: 50% 0;

  &.image-loader {
    right: 50%;
  }
}


@media only screen and (max-width: 768px) {
  .smile-btn {
    padding: 24px 46px 24px 10px;

    img {
      right: 10px;
    }
  }
}


.decrease-img-on-sm {
  @media only screen and (max-width: 768px) {
    max-height: 158px;
    margin-left: 26%;
    margin-top: -53px;
  }
}
.intro-bg {
  background-position-x: right;
  background-repeat: no-repeat;
  background-position-y: 70px;
}

.section-intro {
  height: 100vh;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 120px;

  @media screen and (max-width: 767px) {
    padding-bottom: 0px;
  }

  .container-fluid {
    height: 100%;
    
    @media only screen and (max-width: 767px) {
      height: auto;
    }

    @media screen and (min-width: 1200px) {
      position: fixed;
      top: 80px;
    }
  }
  
  @media only screen and (max-width: 1199px) {
    height: auto;
    position: relative;

    .intro-content {
      padding-top: 35px;
      padding-bottom: 0px;

      .intro-img {
        height: auto;
      }
    }
  }
}

.intro-content {

  padding-top: 142px;
  padding-bottom: 7vh;
  background-position: top 77px right;
  align-items: center;
  display: flex;
  height: 100%;
  

  @media only screen and (max-width: 1020px) {
    padding-bottom: 125px;
  }

  @media only screen and (max-width: 768px) {
    padding-bottom: 0;
    background-position: bottom;
    padding-top: 35px;
  }
  @media only screen and (max-width: 691px) {
    /* padding-bottom: 52%; */
    background-position: bottom;
    padding-top: 35px;
  }
  @media only screen and (max-width: 375px) {
    /* padding-bottom: 89%; */
    background-position: bottom;
    padding-top: 108px;
    background-size: contain;
  }
  .intro-img {
   /*  position: absolute;
    left: -168px;
    top: -57px; */

    position: absolute;
    height: 100vh;
    left: -15vh;
    top: -30vh;

    @media only screen and (max-width: 1199px) {
      position: relative;
      left: 0;
      top: 0;
      width: 120%;
      margin-left: -10%;
      margin-top: 0px;
    }
  }
}
.custom-bg-position {
  background-position: bottom 163px left;
  background-repeat: no-repeat;
}
.bg-green-section {
  background-image: linear-gradient(to right, #9fc162, #8dae65);
}
.section-green {
  min-height: 556px;
  transform: skewY(-#{$skew-1});
  transform-origin: top left;
  
  .skew-back-1 {
    transform: skewY($skew-1);
    transform-origin: top left;

    @media screen and (max-width: 1199px) {
      transform: skewY(8deg);
      
    }
  }
  @media screen and (max-width: 1199px) {
    transform: skewY(-8deg);
    margin-top: -17%;
    padding-bottom: 95px;
  }
  @media screen and (max-width: 375px) {
    padding-bottom: 11%;
  }
}

.perfect-comfort {
  min-height: 556px;
  background-color: #ffffff;
  position: relative;
  z-index: 9999;
  
  .perfect-comfort-content {
    padding-top: 100px;
    padding-bottom: 96px;

    @media screen and (max-width: 767px) {
      padding-bottom: 0;
    }

    @media screen and (max-width: 375px) {
      padding-top: 27px;
    }
  }
}

.abs-img-green {
  @media screen and (min-width: 1200px) {
    position: absolute;
    top: -61px;
    left: -91px;
  }

  .dog-temp-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-shadow: 0 5px 15px rgba(0, 0, 0, 0.16);
    font-size: 50px;
    font-weight: bold;
    color: #ffffff;
    padding-bottom: 67px;


      .dog-name {
        margin-left: 22px;
        
        @media only screen and (max-width: 768px) {
          margin-left: 12px;
        }
      }
      .dog-temp {
        margin-right: 22px;

        @media only screen and (max-width: 768px) {
          margin-right: 12px;
        }
      }
      
      @media only screen and (max-width: 768px) {
        font-size: 25px;
      }
  }

  @media only screen and (max-width: 1199px) {
    position: relative;
    top: 0;
    left: 0;

    img {
      margin: 0 auto;
      display: block;
      width: 100%;
      margin-top: 30px;
    }
  }
}
.green-section-content {
  padding-bottom: 107px;

  @media only screen and (max-width: 1199px) {
    .row .order-on-sm:first-child {
      order: 2;
    }
    padding-bottom: 0px;
  }
}

.energy-efficiency-content, .perfect-comfort-content {

  @media only screen and (max-width: 768px) {
    .row .order-on-sm:first-child {
      order: 2;
    }
    padding-bottom: 0px;
  }
}
.section-prizes {
  transform: skewY($skew-2);
  transform-origin: top left;
  margin-top: -163px;
  padding-top: 65px;
  padding-bottom: 20vh;

  .skew-back-2 {
    transform: skewY(-#{$skew-2});
  }

  @media only screen and (max-width: 375px) {
    transform: skewY($skew-2-sm);
    margin-top: -87px;
    padding-bottom: 200px;

      .skew-back-2 {
        transform: skewY(-#{$skew-2-sm});
      }
  }
}
.bg-prizes-section {
  background-image: linear-gradient(214deg, #ba7ddd, #9d62c8);
}
.section-prizes-content {

  @media only screen and (max-width: 768px) {
      padding-top: 0px;
  }
}

.prize-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    margin-bottom: 45px;
  }
}

.prize-img {
  img {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    max-width: 250px;
    margin: 0 auto;
  }
}
.prizes-container {
  margin-top: 30px;
  margin-bottom: 60px;

  @media only screen and (max-width: 768px) {
    margin-bottom: -10px;
  }
}
.prize-text {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  text-align: center;
  max-width: 229px;
  margin: 0 auto;
  line-height: normal;

  @media only screen and (max-width: 768px) {
    max-width: 220px;
    margin: 0 auto;
  }
}
.prize-img {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  flex-direction: column;
}

.title-md {
  line-height: normal;

  @media screen and (max-width: 375px) {
    text-align: center;
    font-size: 32px;

    .title-md-on-sm {
      font-size: 32px;
    }
  }
}
.prize-text-wrapper {
  text-align: center;
  margin-top: -67px;
  z-index: 1;
}
body {
  overflow-x: hidden;
}
.nav-wrapper {
  background-color: #fff;
  z-index: 9;
  position: relative;
}
.prizes-count {
  width: 76px;
  height: 76px;
  border: solid 5px #ffffff;
  margin: 0 auto;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.bg-prize-1 {
  background-color: #e5a951;
}

.bg-prize-2 {
  background-color: #b1cf7c;
}

.bg-prize-3 {
  background-color: #e05383;
}

.bg-prize-4 {
  background-color: #8e64b6;
}

a.link-small {
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  color: #ffffff;
  text-decoration: underline;
}
.section-gallery {
  transform: skewY($skew-3);
  transform-origin: top left;
  overflow: hidden;
  margin-top: -20vh;
  z-index: 99;
 
  &:after {
    content: '';
    position: absolute;
    left: 0px;
    top: 100%;
    width: 150%;
    height: 15000px;
    background-color: #ffffff;
    transform: rotate(-9deg);
    transform-origin: 0 0;
    z-index: -1;

    @media screen and (max-width: 375px) {
      transform: rotate(-28deg);
    }
  }

  @media screen and (max-width: 375px) {
    transform: skewY($skew-3-sm);
  }
  .skew-back-3 {
    transform: skewY(-#{$skew-3});

    @media screen and (max-width: 375px) {
      transform: skewY(-#{$skew-3-sm});
    }
  }
}

.find-out-more {
  transform: skewY(-#{$skew-4});
  transform-origin: top left;

  
  .skew-back-4 {
    transform: skewY($skew-4);
  }
  
}
.bg-gallery-section {
  background-image: linear-gradient(to top, #e59600, #f6b34e);
  z-index: 999;
}
.gallery-content {
  padding-top: 58px;
  padding-bottom: 100px;

  @media screen and (max-width: 375px) {
    padding-bottom: 40px;
  }
}
.gallery-item {
  position: relative;
  overflow: hidden;
  margin: 10px;
  border-radius: 15px;
}
.gallery-item::before {
  content: '';
  width: 100%;
  padding-bottom: 100%;
  display: block;
}
.gallery-item img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0%;
  left: 50%;
  transform: translate(-50%);
}
.person-info-wrapper {
  position: absolute;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
  padding: 0 10px;
  color: #ffffff;
}
.person-name {
  text-shadow: 0 2px 5px #000000;
  font-size: 18px;
}
.person-temperature {
  text-shadow: 0 2px 5px #000000;
  font-size: 25px;
  font-weight: bold;
}
.person-hashtag {
  position: absolute;
  bottom: 0;
  right: 0;
  text-shadow: 0 2px 5px #000000;
  font-size: 12px;
  color: #ffffff;
  line-height: normal;
  padding: 10px;
}
.gallery-wrapper {
  padding-top: 25px;
  padding-bottom: 60px;
}
.more-about-us {
  padding-top: 74px;
  padding-bottom: 90px;

  @media screen and (max-width: 375px) {
    padding-top: 20px;
    padding-bottom: 25px;
  }
}
.comfort-img {
  border-radius: 5px;
  width: 100%;
  box-shadow: 0 12px 20px 0 rgba(0, 0, 0, 0.2);
}
.btn-blue {
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #0099cc;

  @media screen and (max-width: 767px) {
    margin-bottom: 35px;
  }
}
.btn-blue {
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #0099cc;
  padding: 19px;
  width: 100%;
  text-align: center;
  transition: $hover-transition-time;
  font-size: 18px;
  font-weight: 600;
  color: #09c;
}
.btn-blue:hover {
  background-color: #0099cc;
  color: #ffffff;
}
.comfort-buttons {
  margin-top: 45px;
}
.energy-efficiency {
  background-color: #fff;
}
.energy-efficiency-content {
  padding: 50px 0;

  @media screen and (max-width: 767px) {
    padding: 30px 35px 30px 35px !important;
  }
}
.more-info {
  background-color: #0099cc;
  background-image: url(../images/landscape.png);
  background-position: bottom center;
  background-repeat: repeat-x;
}
.more-info-content {
  padding-top: 90px;
  padding-bottom: 120px;
  color: #fff;
  
  @media screen and (max-width: 767px) {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}
.more-info-title {
  font-size: 21px;
  color: #ffffff;
}
.more-info-button {
  padding: 12px 21px;
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  border-radius: 1px;
  border: solid 1px #ffffff;
  margin-top: 22px;
  transition: 0.3s;
  
  &:hover {
    color: #0099cc;
    background-color: #ffffff;
  }

  @media screen and (max-width: 767px) {
    margin-bottom: 35px;
  }
}

.footer-upper-section {
  background-color: #484e54;

  @media screen and (max-width: 767px) {
    display: none;
  }
}
.footer-link-list ul li {
  font-size: 12px;
  line-height: 21px;
  letter-spacing: normal;
  color: #b4b4b4;
}
.footer-title::after {
  content: '';
  width: 31px;
  height: 3px;
  background-color: #ffffff;
  display: block;
  margin: 15px 0;
}
a:hover {
  text-decoration: none !important;
}
.footer-upper {
  padding-top: 21px;
  padding-bottom: 87px;
}
.footer-lower-section {
  background-color: #292b2e;
}
.footer-lower-urls {
  padding: 15px 0;

  span {
    font-size: 11px;
    color: #b4b4b4;
    margin-right: 15px;

    @media screen and (max-width: 375px) {
      display: block;
      text-align: center;
      margin: 15px 0 15px 0;
    }
  }
  .footer-col-sm {
    display: inline-block;
  }

  @media screen and (max-width: 375px) {
    width: 100%;

    a {
      span {
        text-align: left;
        margin: 15px 0;
      }
    }
  .footer-col-sm {
    width: 49%;
    vertical-align: top;
    padding-left: 10px;
  }
}
  
}
.footer-lower-social {
  padding: 15px 0;
}
@media screen and (max-width: 375px) {
  .footer-lower-social {
    margin: 0 auto;
    padding-top: 10px;
    padding-bottom: 30px;
  }
}

.copy-right-span {
  font-weight: bold;
  color: #ffffff !important;
}

.footer-lower-social img {
  margin-left: 16px;
}

.pagination ul li {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background-color: #c57e14;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 3px;
  font-size: 16px;
  font-weight: 300;
  color: #ffffff;
  transition: 0.3s;
  cursor: pointer;

  &.pagination-btn:hover, &.pagination-active {
    background-color: #ffffff;
    color: #f6b34e;
  }

  @media screen and (max-width: 450px) {
    width: 32px;
    height: 32px;
    margin: 0 1px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.left-discover {
  height: 340px;
  background-color: #f8f8f8;

  @media screen and (max-width: 768px) {
    :nth-child(2) {
      position: absolute;
      top: 0;
      left: 0;
      
      .discover-img-left {
        position: absolute;
        max-width: 330px;
        margin: 0 auto;
        top: 50%;
        transform: translate(50%,50%);
      }
    }
  }
}
.right-discover {
  height: 340px;
  background-image: radial-gradient(circle at 50% 0, #43a7e5, #50b1e8);
  
  @media screen and (max-width: 767px) {
    height: 385px;
    overflow: hidden;
  }
}

.halved-section {
  /* margin-top: 70px; */
}
.title-discover-left {
  right: 30px;
  position: absolute;
  bottom: 45px;
  text-align: right;
}
.title-discover-right {
  left: 30px;
  position: absolute;
  bottom: 45px;
  text-align: left;
}
/* .oblique-2 {
  width: calc(100% + 40px);
  height: 100%;
  content: '';
  display: block;
  transform: rotate(2deg) translateX(-20px);
  position: absolute;
  top: -54px;
  background-image: linear-gradient(214deg, #ba7ddd, #9d62c8);

  @media screen and (max-width: 767px) {
    width: 140%;
    height: 10%;
    transform: rotate(8deg) translateX(-20px);
    top: -40px;
    left: -20%;
    background-image: linear-gradient(#ba7ddd, #b27adc);
  }
} */

.container-fluid {
  transition: transform $slide-time linear;
}
.section-game {
  position: relative;
  overflow: hidden;
  display: none;
}
.owl-item {
  // width: 100vw !important;
  img {
    width: auto!important;
  }
}

.app-gallery-item {
  position: absolute;
  border-radius: 15px;
  overflow: hidden;

  &.app-imgs-left {
    left: -50%;
  }
  &.app-imgs-right {
    right: -50%;
  }

  &.img-rotate-minus {
    transform: rotate(15deg);
  }
  &.img-rotate-plus {
    transform: rotate(-15deg);
  }
}
.app-column-rows {
  .row {
    height: 33.333%;

    .col-md-12 {
      display: flex;
      align-items: center;
    }
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.app-title {
  font-size: 36px;
}
.app-text {
  font-size: 20px;
}
.app-text-lg {
  font-size: 22px;
  font-weight: normal;
  line-height: 34px;

  @media screen and (max-width: 375px) {
    font-size: 16px;
    line-height: normal;
  }
}
.app-label {
  font-size: 18px;
  padding: 20px 0 16px 0;
}
.app-label-lg {
  font-size: 26px;
  margin: 21px 0;

  @media screen and (max-width: 375px) {
    font-size: 18px;
  }
}
.step-1-center {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;

  img {
    margin-bottom: 40px;
  }
}
.step-1-center,
.step-2-center,
.js-slide-3,
.js-slide-6 {
  padding-top: 50px;
}

.js-slide-4 {
  padding-bottom: 17vh;
}

.js-slide-5,
.js-slide-6 {
  padding-bottom: 20vh;
}

.app-to-step-4 img {
  top: 50%;
  transform: translateY(-50%);
}

.slide-out {
  transform: translate(-120%, 0);
}
.step-1-center,.step-2-center {
  div, img {
    z-index: 9;
  }
}
.name-input {
  font-size: 26px;
  padding: 27px 15px;
  border-radius: 5px;
  background-color: #ffffff;
  border: none;

  @media screen and (max-width: 375px) {
    font-size: 18px;
    padding: 17px 15px;
  }
}
.bg-slide-1 {
  background-image: -moz-radial-gradient(circle at 51% 52%, #4aace7, #5fc8ff 79%);
  background-image: -webkit-radial-gradient(circle at 51% 52%, #4aace7, #5fc8ff 79%);
  background-image: -o-radial-gradient(circle at 51% 52%, #4aace7, #5fc8ff 79%);
  background-image: -ms-radial-gradient(circle at 51% 52%, #4aace7, #5fc8ff 79%);
  background-image: radial-gradient(circle at 51% 52%, #4aace7, #5fc8ff 79%);
}
.bg-slide-2, .bg-slide-3 {
  background: -moz-radial-gradient(circle at 26% 31%, #2a52d394, #809fff96), url('https://smile.daikin.hr/source/images/beach-bg.jpg') center/cover no-repeat;
  background: -webkit-radial-gradient(circle at 26% 31%, #2a52d394, #809fff96), url('https://smile.daikin.hr/source/images/beach-bg.jpg') center/cover no-repeat;
  background: -o-radial-gradient(circle at 26% 31%, #2a52d394, #809fff96), url('https://smile.daikin.hr/source/images/beach-bg.jpg') center/cover no-repeat;
  background: -ms-radial-gradient(circle at 26% 31%, #2a52d394, #809fff96), url('https://smile.daikin.hr/source/images/beach-bg.jpg') center/cover no-repeat;
  background: radial-gradient(circle at 26% 31%, #2a52d394, #809fff96), url('https://smile.daikin.hr/source/images/beach-bg.jpg') center/cover no-repeat;
}
.bg-slide-4 {
  background-repeat: repeat;
  background-position: center;
  background-image: url('https://smile.daikin.hr/source/images/smile-texture.png');
  background-color: #f6b34c;
}
.bg-slide-5 {
  background-repeat: repeat;
  background-position: center;
  background: url('https://smile.daikin.hr/source/images/smile-texture.png'), radial-gradient(circle farthest-side, #4aace7 , #4aace7);

  
  .game-content {
    background: radial-gradient(circle farthest-side, #4aace7, #4aace705);
  }
}
.increased-zindex {
  /* z-index: 2; */
}
.name-font-xlg {
  font-size: 56px;
}
.temp-font-xlg {
  font-size: 100px;
  font-weight: bold;
}
.discover {
  z-index: 9;
  background-color: #ffffff;

  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
}
.discover-img-left {
  position: absolute;
  top: -60px;
  right: 50%;
}

.discover-img-right {
  position: absolute;
  left: 56%;
  bottom: 0;
  
  @media screen and (max-width: 768px) {
    left: 50%;
    transform: translateX(-50%);
    bottom: -35%;
    max-width: 265px;
  }
}
.discover-rows-wrapper {
  min-height: 347px;
  margin-top: 70px;
  position: relative;

  .container-fluid {
    position: absolute;

    @media screen and (max-width: 768px) {
      position: relative;
    }
  }

  @media screen and (max-width: 768px) {
    margin-top: 30px;
  }
}
.abs-bottom-right {
  bottom: 45px;
  position: absolute;
  right: 30px;

  @media screen and (max-width: 767px) {
    left: 30px;
    right: 0;
    text-align: left;
    width: 100%;
    bottom: 30px;
  }
}
.abs-bottom-left {
  bottom: 45px;
  position: absolute;
  left: 30px;

  @media screen and (max-width: 768px) {
    top: 30px;
  }
}

.nav-list-items {
  /* display: none; */
  width: 100%;
}
.navbar-buttons {
  display: none;
}

.comfort-btns-md-down {
  display: none;
}

.nav-list-items {
  height: 0;
  opacity: 0;
  visibility: hidden;
}

@media screen and (max-width: 767px) {

  .energy-efficiency div, .perfect-comfort div {
    text-align: left;
  }

  .comfort-btns-md-up {
    display: none !important;
  }
  .comfort-btns-md-down {
    display: flex;
  }

  .fa-map-pin:before {
    content: "\f276";
  }
  .fa-book:before {
    content: "\f02d";
  }

  .navbar-buttons {
    display: inline;
  }

  .logo-wrapper {
    display: flex;
    justify-content: space-between;
    text-align: center;
    flex-direction: row;
  }

  .navsec-1-items, .nav-section-2 {
    display: none !important;
  }

  .nav-list-items {
      position: absolute;
      left: 0;
      overflow-y: auto;
      background-color: #fff;
      top: 90px;
      height: 0;
      overflow-y: auto;


      ul {
        padding: 6px 24px 0px 33px;
        font-size: 20px;

        li {
            line-height: 38px;
            border-bottom: 1px solid #f5f5f5;
        }

        a {
          color: rgb(51, 51, 51);
          text-decoration: none;
          font-size: 14px;
          color: #8e8e8e;
          text-transform: uppercase;
          text-align: center;
          border-bottom: 1px solid #f5f5f5;
          display: flex;
          justify-content: center;

          :hover {
            color: #515151;
          }

          img {
            margin-right: 5px;
          }
        }
      }
  }

  .increase-nav-height {
    height: auto !important;
    opacity: 1;
    visibility: visible;
  }

  .hamburger {
      display: flex;
      height: 30px;
      width: 22px;
      background-color: #fff;
      z-index: 9999;
      position: relative;
      left: 14px;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
  }

  .hamburger span {
    display: block;
    height: 2px;
    width: 100%;
    background-color: #515151;
    transition: 0.3s;
  }

  .hamburger span::before {
    display: block;
    height: 2px;
    width: 100%;
    content: '';
    background-color: #515151;
    top: 5px;
    position: absolute;
    transition: 0.3s;
  }

  .hamburger span::after {
    display: block;
    height: 2px;
    width: 100%;
    content: '';
    background-color: #515151;
    bottom: 5px;
    position: absolute;
    transition: 0.3s;
  }

  .slide-nav-list {
    top: 90px;
  }

  .animate-hamburger::before {
    transform: rotate(45deg);
    top: 14px !important;
  }
  .animate-hamburger::after {
    transform: rotate(-45deg);
    top: 14px;
  }
  .w-0 {
    width: 0 !important;
  }
  .body-overflow {
    overflow: hidden !important;
  }
  .nav-box-shadow {
    box-shadow: -4px 0px 10px #0b0b0b;
  }

.button-mobile img {
    margin-right: 20px;
}

/*   .nav-section-2 .navsec-2-group-1 ul, .nav-section-1 .navsec-1-items ul, .nav-section-2 .navsec-2-group-2 ul {
    display: block;
  }

  .navsec-1-items, .nav-section-2 {
    transform: translateX(-100%);
    background-color: red;
    position: absolute;
    top: 90px;
  } */

}

.range-slider input {
  width: 100%;
}

.range-slider {
  width: 100%;
  margin-top: 10px;
}

.minmax-temp-row {
  padding: 0 15px;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 28px;

  .edge-temp {
    font-size: 24px;

    @media screen and (max-width: 375px) {
      font-size: 16px;
    }
  }
  .cur-temp {
    font-size: 40px;
    font-weight: bold;

    @media screen and (max-width: 375px) {
      font-size: 20px;
    }
  }
}

.temp-range-slider {
  -webkit-appearance: none;
  height: 8px;
  background-image: linear-gradient(to left, #ff6400, #6de56b);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.19);
  border-radius: 4px;
}

.temp-range-slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 41px;
  height: 41px;
  background-color: #ffffff;
  background-image: url(../../dist/images/smiley-orange.png);
  cursor: pointer;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.temp-range-slider::-moz-range-thumb {
  width: 41px;
  height: 41px;
  background-color: #ffffff;
  background-image: url(../../dist/images/smiley-orange.png);
  cursor: pointer;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.temp-range-slider::-ms-thumb {
  width: 41px;
  height: 41px;
  background-color: #ffffff;
  background-image: url(../../dist/images/smiley-orange.png);
  cursor: pointer;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
}

.uploaded-photo-wrapper {
  width: 100%;
  max-width: 768px;
  padding-bottom: 14%;
  margin: 0 auto;
}
.uploaded-photo {
  background-image: url(dist/images/person-8.png);
  background-repeat: no-repeat;
  width: 100%!important;
  height: auto !important;
  padding-bottom: 100%!important;
  background-position: top;
  background-size: contain;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .intro-content {
    .row {
      width: 100%;
    }
  }
}

.load-photo-popup {
  width: 50%;
  text-align: center;
  border-radius: 5px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  padding: 50px;
  z-index: 9;
}

@media screen and (max-width: 1024px) {
  .js-text-name,
  .js-text-temp {
    display: none;
  }
}

.change-photo-btn {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 22vh;
  background-color: transparent;
  border: none;
  padding-left: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    position: absolute;
    left: 0px;
  }
  
  @media screen and (max-width: 768px) {
    left: 20px;
  }
}

.upload-photo-btn {
  font-size: 18px;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  color: #505050;
  position: absolute;
  right: 0;
  bottom: 20vh;
  border: none;
  border-radius: 4px;
  box-shadow: 0 28px 15px -22px rgba(0, 0, 0, 0.53);
  background-color: #ffffff;
  padding: 13px 44px 13px 22px;
  display: flex;
  align-items: center;

  img {
    position: absolute!important;
    right: 15px!important;
    top: 50%;
    transform: translateY(-50%);
  }

  @media screen and (max-width: 768px) {
    right: 20px;
  }
}

// custom checkboxes

/* Customize the label (the container) */
.chcks-mandatory {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  a {
    color: #ffff;
  }
}

/* Hide the browser's default checkbox */
.chcks-mandatory input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 5px;
  background-color: #ededed;
}

/* On mouse-over, add a grey background color */
.chcks-mandatory:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.chcks-mandatory input:checked ~ .checkmark {
  background-color: #2196F3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.chcks-mandatory input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.chcks-mandatory .checkmark:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.app-contact-input {
  width: 100%;
  padding: 11px;
  border: none;
  border-radius: 5px;
  background-color: #ededed;
}

.final-info {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title-md {
    margin-bottom: 45px;
  }

  .progress-loader {
    position: relative;
    bottom: 0px;
    left: 0;
    transform: none;
  }

  .person-name {
    font-size: 28px;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 28px;
    text-shadow: none;
  }

  .fb-box {
    border-radius: 3px;
    box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px #979797;
    background-color: #ffffff;
    padding: 27px;
    text-align: center;
    margin-top: 40px;

    .fb-title {
      text-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
      font-size: 20px;
      font-weight: bold;
      line-height: 31px;
      color: #515151;
      text-transform: uppercase;
      margin-bottom: 14px;
    }
    
    .fb-text {
      font-size: 17px;
      font-weight: 600;
      line-height: normal;
      color: #515151;
    }

    .fb-button {
      padding: 18px 29px 18px 72px;
      margin-top: 26px;

      cursor: pointer;

      img {
        left: 24px;
      }
    }

    .fb-deny {
      cursor: pointer;
      font-size: 15px;
      font-weight: 600;
      line-height: normal;
      color: #515151;
      margin-top: 17px;
      text-transform: uppercase;
    }
  }

  .person-info-wrapper {
    .person-name {
      text-shadow: 0 3px 9px rgba(0, 0, 0, 0.55);
      font-size: 30px;
      font-weight: normal;
      line-height: normal;
      color: #ffffff;
      margin-bottom: 0;
    }
    .person-temperature {
      text-shadow: 0 3px 9px rgba(0, 0, 0, 0.55);
      font-size: 42px;
      font-weight: bold;
      line-height: normal;
      text-align: right;
      color: #ffffff;
    }
  }
}

.smile-measure-btns {
  display: none;
  justify-content: space-between;

  @media screen and (max-width: 767px) {
    margin-top: 20px;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .app-column-rows .row .col-md-12 {
    align-items: flex-start;
  }

  .game-content {
    .app-text-lg {
      max-width: 600px;

      @media screen and (max-width: 650px) {
        max-width: 420px;
      }
    }
  }

  .bg-slide-2 {
    background: -webkit-radial-gradient(26% 31%, circle cover, #2a52d3, #809fff), url(../images/beach-bg.jpg) center/cover no-repeat;
    background: -o-radial-gradient(26% 31%, circle cover, #2a52d3, #809fff), url(../images/beach-bg.jpg) center/cover no-repeat;
    background: radial-gradient(26% 31%, circle cover, #2a52d3, #809fff), url(../images/beach-bg.jpg) center/cover no-repeat
  }
}


.app-name {
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.app-temp {
  position: absolute;
  right: 0px;
  top: 50%;
  transform: translateY(-50%);
}


.close-popup {
  width: 40px!important;
  height: 40px!important;
  position: absolute;
  top: -20px!important;
  right: -20px!important;
  border-radius: 50%;
  border: 2px solid #e98400;
  background: #ffffff!important;
  opacity: 1!important;

  svg {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    fill: #e98400;
    pointer-events: none;
  }
}

.mfp-arrow {
  width: 128px !important;
  height: 115px !important;
  top: 71% !important;
  opacity: 1 !important;
}

.mfp-arrow:before, .mfp-arrow:after {
  content: none !important;
}
.mfp-arrow-right {
  right: 5% !important;

  @media screen and (max-width: 375px) {
    margin-top: 18% !important;
    margin-right: 7% !important;
  }
}
.mfp-arrow-left {
  left: 5% !important;

  @media screen and (max-width: 375px) {
    margin-top: 18% !important;
    margin-left: 7% !important;
  }

}
.mfp-arrow:before {
  content: '' !important;
  display: block;
  width: 128px !important;
  height: 114px !important;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 0px !important;
  margin-left: 0px !important;
  margin-top: 35px;
  border: none !important;
  background-size: contain;
  background-repeat: no-repeat;
  right: 0;
  opacity: 1 !important;
}

.mfp-arrow-left:before {
  left: 5% !important;
  background-image: url(../../dist/images/arr-left.svg);
}

.mfp-arrow-right:before {
  right: 5% !important;
  background-image: url(../../dist/images/arr-right.svg);
}

.mfp-arrow:after {
  content: none;
}

img.mfp-img {
  padding: 0px 0 111px !important;
}

.mfp-bottom-bar {
  margin-top: -100px !important;
}

.mfp-figure:after {
  box-shadow: none !important;
  background: none !important;
}

.mfp-title {
  padding-right: 0 !important;
}

.mfp-figure small, .mfp-counter {
  display: none !important;
}

.fb-share-btn {
  left: 50%;
  transform: translateX(-50%);
  text-align: center;

  @media (max-width: 450px) {
    padding: 24px 18px 24px 18px;

    img {
      display: none;
    }
  }
}


@media (max-width: 450px) {
  .mfp-gallery .mfp-container {
    padding-left: 50px !important;
    padding-right: 50px !important;
  }

  .mfp-arrow {
    width: 101px !important;
    height: 115px !important;
    bottom: 35px !important;

    &:before {
      width: 103px !important;
      height: 85px !important;
    }
  }
}

.owl-stage-outer.owl-height {
  min-height: 100vh;
}

.owl-item.active {
  min-height: 100vh;
}

.game-slide {
  min-height: 100vh;

  .game-content {
    min-height: 100vh;

    &>.row {
      min-height: 100vh;
    }
  }
}

.js-slide-2, .js-slide-3 {
  .container {
    min-height: 100vh;

    &>.row {
      min-height: 80vh;
    }
  }
}

.js-slide-4 {
  .container {
    min-height: 100vh;

    &>.row {
      min-height: 80vh;
    }
  }
}

.last-ste-fadout {
  .progress-loader {
    top: 0;
  }
}

.js-slide-6 {
  .final-info {
    min-height: auto;
    
    & > .row {
      min-height: auto;
    }
  }
} 



.form-message p {
  color: #FF0000;
}

.fb-box + .button {
  margin-top: 20px;
}

.app-typeface {
  font-weight: bold;
  font-size: 1.6em;
  color: #ffffff;
  text-transform: uppercase;
}

.pravila-sudjelovanja table {
  tr {
    padding: 5px 0px;
    border-bottom: 1px solid #ababab;
  }
}

body {
  background-color: #292b2e!important;
}

body > img {
  display: none;
}

.single-image {
  background-repeat: repeat;
  background-position: center;
  background-image: url(../images/smile-texture.png);
  background-color: #f6b34c;

  > div {
    min-height: 100vh;
  }

  &-left {
    img {
      width: 100%;

      @media screen and (max-width: 767px) {
        margin-top: 15px;
      }
    }
  }

  &-right {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    
    h1 {
      color: #ffffff;
      text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
    }
    p {
      color: #ffffff;
      font-size: 1.1em;
      text-shadow: 0px 2px 15px rgba(0, 0, 0, 0.5);
    }

    a {
      height: 60px;
      line-height: 60px;
      padding: 0px 40px 0px 30px;
      text-transform: uppercase;
      border-radius: 4px;
      box-shadow: 0 28px 15px -22px rgba(0, 0, 0, 0.3);
      background-color: #ffffff;
      display: inline-block;
      color: #000;
      transition: all 200ms ease-in-out;

      svg {
        margin-right: 20px;
        fill: #f6b34c;
        transition: all 200ms ease-in-out;
      }

      &:hover {
        background-color: #f6b34c;
        color: #fff;

        svg {
          fill: #ffffff;
        }
      }
    }
  }
}

.cookie-consent {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background-color: rgba(250, 250, 250, 0.7);
  padding: 0px 15px;
  text-align: center;
  z-index: 9999;

  p {
    display: inline-block;
    margin: 15px 0px;
  }

  a {
    color: black;
    font-weight: bold;
    text-decoration: underline;
  }

  &-agree {
    background-color: #e98400;
    color: #ffffff;
    margin: 15px 0px;
    padding: 6px 20px;
    border-radius: 4px;
    border: none;
    display: inline-block;
    margin-left: 15px;

    @media screen and (max-width: 767px) {
      margin-left: 0px;
      margin-top: 0px;
    }
  }
}

.progress-static-final {
  position: relative;
  left: 0;
  transform: none;
  top: 0;
}



.popup { opacity: 0; visibility: hidden; -webkit-transition: all .3s; -o-transition: all .3s; transition: all .3s; font-family: 'AdihausDIN', sans-serif; }

.popup.is-visible { opacity: 1; visibility: visible; }

.popup, .popup-back { position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 10; }

.mfp-bg { opacity: 1; background-color: rgba(0, 21, 83, 0.75); }

.popup-inner-wrapper { position: absolute; top: 50%; left: 50%; width: 100%; height: 100%; padding: 15px; z-index: 11; text-align: center; display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-transform: translate(-50%, -50%); -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%); pointer-events: none; }

.close-popup { position: absolute; top: -10px; right: -10px; width: 48px; height: 48px; line-height: 48px; font-size: 20px; text-align: center; color: #000000; border-radius: 50%; background-image: -webkit-gradient(linear, left top, left bottom, color-stop(2%, #cf1d1d), to(#bc1818)); background-image: -webkit-linear-gradient(top, #cf1d1d 2%, #bc1818); background-image: -o-linear-gradient(top, #cf1d1d 2%, #bc1818); background-image: linear-gradient(to bottom, #cf1d1d 2%, #bc1818); -webkit-box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.5); box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.5); cursor: pointer; z-index: 1046; }

.popup-terms { margin: 0 10px; padding-bottom: 20px; padding-right: 10px; padding-top: 20px; padding-left: 20px; background: #fff; border-radius: 2px; max-width: 800px; position: relative; color: #000000; height: calc(100vh - 120px); font-size: 15px; font-weight: 500; font-style: normal; font-stretch: normal; line-height: 1.53; letter-spacing: 0.4px; }

@media (min-width: 768px) { .popup-terms { margin: 0 auto; padding-bottom: 30px; padding-right: 20px; padding-top: 60px; padding-left: 60px; font-size: 17px; } }

@media (min-width: 768px) { .popup-terms-h-auto { height: auto; } }

.popup-terms .popup-title { color: #007fc5; text-transform: uppercase; font-size: 20px; font-weight: 500; font-style: normal; font-stretch: normal; line-height: 1.67; letter-spacing: -0.8px; margin-bottom: 15px; }

@media (min-width: 768px) { .popup-terms .popup-title { font-size: 24px; margin-bottom: 30px; } }

.popup-inner { height: 100%; overflow: hidden; position: relative; padding-bottom: 40px; padding-right: 20px; -webkit-overflow-scrolling: touch;font-family: 'AdihausDIN', sans-serif; }

@media (min-width: 768px) { .popup-inner { padding-right: 40px; } }

.popup-terms .ps__rail-y { display: block; opacity: 1; -webkit-transition: background-color .2s linear, opacity .2s linear; -o-transition: background-color .2s linear, opacity .2s linear; transition: background-color .2s linear, opacity .2s linear; width: 15px; right: 0; position: absolute; }

.popup-terms .ps--active-y > .ps__rail-y { display: block; }

.popup-terms .ps:hover > .ps__rail-x, .popup-terms .ps:hover > .ps__rail-y, .popup-terms .ps--focus > .ps__rail-x, .popup-terms .ps--focus > .ps__rail-y, .popup-terms .ps--scrolling-x > .ps__rail-x, .popup-terms .ps--scrolling-y > .ps__rail-y { opacity: 1; }

.popup-terms .ps .ps__rail-x:hover, .popup-terms .ps .ps__rail-y:hover, .popup-terms .ps .ps__rail-x:focus, .popup-terms .ps .ps__rail-y:focus, .popup-terms .ps .ps__rail-x.ps--clicking, .popup-terms .ps .ps__rail-y.ps--clicking { background-color: #d7d7d7; opacity: 1; }

.popup-terms .ps__thumb-x { background-color: #000000; border-radius: 6px; -webkit-transition: background-color .2s linear, height .2s ease-in-out; -o-transition: background-color .2s linear, height .2s ease-in-out; transition: background-color .2s linear, height .2s ease-in-out; height: 6px; bottom: 2px; position: absolute; }

.popup-terms .ps__thumb-y { background-color: #000000; border-radius: 2px; width: 6px; right: 2px; position: absolute; }

.popup-terms .ps__rail-x:hover > .ps__thumb-x, .popup-terms .ps__rail-x:focus > .ps__thumb-x, .popup-terms .ps__rail-x.ps--clicking .ps__thumb-x { background-color: #000000; height: 11px; }

.popup-terms .ps__rail-y:hover > .ps__thumb-y, .popup-terms .ps__rail-y:focus > .ps__thumb-y, .popup-terms .ps__rail-y.ps--clicking .ps__thumb-y { background-color: #000000; }

.popup-terms .ps__rail-x { display: none; }

.popup-terms .ps__rail-y, .popup-terms .ps__rail-y:hover { opacity: 1; width: 17px; background-color: #ffffff; border-radius: 3px; border: 1px solid #979797; height: 100%; }

.popup-terms .ps__rail-y .ps__thumb-y, .popup-terms .ps__rail-y:hover .ps__thumb-y { opacity: 1; width: 13px; right: 1px; background-color: #007fc5; }

.popup-terms h2 { margin-bottom: 30px; }

.popup-terms p a { color: #000000; font-weight: bold; text-decoration: underline; }

.popup-inner h3 { margin-bottom: 20px; font-size: 20px; }

.popup-inner h4 { text-align: center; margin-bottom: 20px; margin-top: 40px; font-size: 18px; }

.popup-inner p { margin-bottom: 15px; color: #222; }

.popup-inner p:last-of-type { margin-bottom: 0; }

.popup .notice { position: relative; max-width: 500px; text-align: left; background: #fff; color: #222; padding: 15px 20px; border-radius: 5px; pointer-events: all; }

.popup .close-notice { top: -25px; }

.popup-wrapper-main { display: none; position: fixed; width: 100%; height: 100vh; top: 0; left: 0; z-index: 555; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; overflow-y: scroll; background-color: rgba(0, 21, 83, 0.75); }

.popup-wrapper-main .popup-terms { height: calc(100vh - 50px); }

@media (min-width: 576px) { .popup-wrapper-main { overflow: hidden; }
  .popup-wrapper-main .popup-terms { height: auto; } }

.popup-wrapper-main.is-visible { display: -webkit-box !important; display: -ms-flexbox !important; display: flex !important; }

.oh { overflow: hidden; }

.popup-main { margin: 0 20px; }

@media (max-width: 767px) { .popup-main { background: transparent; padding: 0; }
  .popup-main .popup-inner { padding-bottom: 20px; padding-right: 10px; padding-top: 20px; padding-left: 20px; background: #fff; } }

.popup-main-buttons { margin-top: 3rem; }

.popup-main-buttons .btn:first-child { margin-bottom: 2rem; }


@media (min-width: 576px) { .popup-main-buttons .btn:first-child { margin-bottom: 0; } }

.terms-accept-small { position: fixed; bottom: 0; left: 0; width: 100%; padding: 2rem 1.5rem; font-size: 16px; line-height: 1.44; background-color: #ffffff; font-family: 'AdihausDIN', sans-serif;  z-index: 1000; -webkit-box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.3); box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.3); -webkit-transform: translate3d(0, 150%, 0); transform: translate3d(0, 150%, 0); -webkit-transition: -webkit-transform .3s ease; transition: -webkit-transform .3s ease; -o-transition: transform .3s ease; transition: transform .3s ease; transition: transform .3s ease, -webkit-transform .3s ease; }

@media (min-width: 768px) { .terms-accept-small { padding: 20px 60px; font-size: 17px; } }

.terms-accept-small.is-visible { bottom: 0; -webkit-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.terms-accept-small .link { padding-top: 2.5rem; }

.terms-accept-small-inner { text-align: center; font-size: 14px; color: #000a12; line-height: 1.5; }

.terms-accept-small-inner p { margin-bottom: 2.5rem; }

.terms-accept-small-inner .open-popup { color: #000a12; text-decoration: underline; font-weight: bold; }

@media (min-width: 768px) { .terms-accept-small-inner { display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; -webkit-box-align: center; -ms-flex-align: center; align-items: center; }
  .terms-accept-small-inner p { font-size: 16px; padding-right: 30px; margin-bottom: 0; -webkit-box-flex: 1; -ms-flex: 1; flex: 1; } }

.indented { padding-left: 20px; }

.mfp-close { background: url("../img/close-btn.svg") no-repeat; background-position: center center; background-size: contain; width: 58px; height: 54px; }

@media screen and (max-width: 767px) { .mfp-close { margin-right: 6px !important; width: 40px; height: 40px; } .popup-terms .ps__rail-y .ps__thumb-y, .popup-terms .ps__rail-y:hover .ps__thumb-y { width: 9px; } .popup-terms .ps__rail-y, .popup-terms .ps__rail-y:hover { width: 13px; } }

.mfp-img-wrapper { position: relative; margin-bottom: 1.6rem; padding: 0px 1rem; }

@media screen and (min-width: 767px) { .mfp-img-wrapper { margin-bottom: 2.4rem; } }

@media screen and (min-width: 767px) { .mfp-container { padding: 0 21px; } }

/*==================================================================================================
x.  Popup
================================================================================================= */
.mfp-hide { display: none !important; }

.mfp-bg, .mfp-wrap { position: fixed; left: 0; top: 0; }

.mfp-bg, .mfp-container, .mfp-wrap { height: 100%; width: 100%; }

.mfp-container:before, .mfp-figure:after { content: ''; }

.mfp-bg { z-index: 1042; overflow: hidden; background: #000; opacity: .8; }

.mfp-wrap { z-index: 1043; outline: 0 !important; -webkit-backface-visibility: hidden; }

.mfp-container { text-align: center; position: absolute; left: 0; top: 0; padding: 0 10px; }

@media screen and (min-width: 767px) { .mfp-container { padding: 0 21px; } }

.mfp-container:before { display: inline-block; height: 100%; vertical-align: middle; }

.mfp-align-top .mfp-container:before { display: none; }

.mfp-content { position: relative; display: inline-block; vertical-align: middle; margin: 0 auto; text-align: left; z-index: 1045; }

.mfp-ajax-holder .mfp-content, .mfp-inline-holder .mfp-content { width: 100%; cursor: auto; }

.mfp-ajax-cur { cursor: progress; }

.mfp-auto-cursor .mfp-content { cursor: auto; }

.mfp-arrow, .mfp-close, .mfp-counter, .mfp-preloader { -webkit-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; }

.mfp-preloader { position: absolute; top: 50%; left: 0; width: 100%; height: 18px; color: #fff; text-align: center; margin-top: -9px; margin-left: -35px; z-index: 1044; }

.mfp-s-error .mfp-content, .mfp-s-ready .mfp-preloader { display: none; }

.mfp-close { position: absolute; top: -22px; right: -22px; margin-top: 0; margin-right: 0; color: #fff; cursor: pointer; z-index: 1046; -ms-touch-action: manipulation; touch-action: manipulation; }

.mfp-inline-holder .mfp-content { max-width: 900px; }

.mfp-counter { position: absolute; top: 0; right: 0; color: #CCC; font-size: 12px; line-height: 18px; white-space: nowrap; }

.mfp-figure, img.mfp-img { line-height: 0; }

.mfp-arrow { position: absolute; z-index: 1046; margin: -20px 0 0; top: 50%; padding: 0; -ms-touch-action: manipulation; touch-action: manipulation; width: 80px; height: 60px; line-height: 40px; text-align: center; font-size: 20px; border-radius: 50%; color: #fff; -webkit-tap-highlight-color: transparent; cursor: pointer; -webkit-transition: all .3s; -o-transition: all .3s; transition: all .3s; background-size: contain; background-repeat: no-repeat; }

.mfp-arrow:hover { opacity: 1; }

.mfp-arrow:focus, .mfp-arrow:hover { opacity: 1; }

.mfp-arrow-left { background-image: url("/assets/img/strelica-left.svg"); left: 25px; }

.mfp-arrow-right { background-image: url("/assets/img/strelica-right.svg"); right: 25px; }

.mfp-iframe-holder { padding-top: 40px; padding-bottom: 40px; }

.mfp-iframe-holder .mfp-content { line-height: 0; width: 100%; max-width: 900px; }

.mfp-image-holder .mfp-content, img.mfp-img { max-width: 100%; }

.mfp-iframe-holder .mfp-close { top: -40px; }

.mfp-iframe-scaler { width: 100%; height: 0; overflow: hidden; padding-top: 56.25%; }

.mfp-iframe-scaler iframe { display: block; top: 0; width: 100%; height: 100%; background: #000; }

img.mfp-img { width: auto; height: auto; display: block; padding: 0; margin: 0 auto; }

.mfp-figure { position: relative; margin-top: 40px; }

.mfp-figure small { color: #BDBDBD; display: block; font-size: 12px; line-height: 14px; }

.mfp-figure figure { margin: 0; }

.mfp-bottom-bar { margin-top: -36px; position: absolute; top: 100%; left: 0; width: 100%; cursor: auto; }

.mfp-title { text-align: left; line-height: 18px; color: #F3F3F3; word-wrap: break-word; padding-right: 36px; }

.mfp-gallery .mfp-image-holder .mfp-figure { cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) { .mfp-img-mobile .mfp-image-holder { padding-left: 0; padding-right: 0; }
  .mfp-img-mobile img.mfp-img { padding: 0; }
  .mfp-img-mobile .mfp-figure:after { top: 0; bottom: 0; }
  .mfp-img-mobile .mfp-figure small { display: inline; margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar { background: rgba(0, 0, 0, 0.6); bottom: 0; margin: 0; top: auto; padding: 3px 5px; position: fixed; }
  .mfp-img-mobile .mfp-bottom-bar:empty { padding: 0; }
  .mfp-img-mobile .mfp-counter { right: 5px; top: 3px; }
  .mfp-img-mobile .mfp-close { top: 0; right: 0; width: 35px; height: 35px; line-height: 35px; background: rgba(0, 0, 0, 0.6); position: fixed; text-align: center; padding: 0; } }

@media all and (max-width: 680px) { .mfp-close { top: -20px; right: -12px; } }

@media all and (max-width: 900px) { .mfp-arrow { -webkit-transform: scale(0.75); -ms-transform: scale(0.75); transform: scale(0.75); top: 45%; }
  .mfp-arrow-left { -webkit-transform-origin: 0; -ms-transform-origin: 0; transform-origin: 0; }
  .mfp-arrow-right { -webkit-transform-origin: 100%; -ms-transform-origin: 100%; transform-origin: 100%; }
  .mfp-arrow-left { left: 5px; }
  .mfp-arrow-right { right: 5px; } }

.mfp-container { overflow: hidden; position: fixed; }

.mfp-popup-footer { display: -webkit-box; display: -ms-flexbox; display: flex; -ms-flex-wrap: wrap; flex-wrap: wrap; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; padding: 0 1rem; }
