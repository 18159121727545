@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg) translateY(-50%);
    -o-transform: rotate(0deg) translateY(-50%);
    transform: rotate(0deg) translateY(-50%);
  }
  to {
    -webkit-transform: rotate(360deg) translateY(-50%);
    -o-transform: rotate(360deg) translateY(-50%);
    transform: rotate(360deg) translateY(-50%);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg) translateY(-50%);
    -moz-transform: rotate(0deg) translateY(-50%);
    -webkit-transform: rotate(0deg) translateY(-50%);
    -o-transform: rotate(0deg) translateY(-50%);
    transform: rotate(0deg) translateY(-50%);
  }
  to {
    -ms-transform: rotate(360deg) translateY(-50%);
    -moz-transform: rotate(360deg) translateY(-50%);
    -webkit-transform: rotate(360deg) translateY(-50%);
    -o-transform: rotate(360deg) translateY(-50%);
    transform: rotate(360deg) translateY(-50%);
  }
}
.rotate-sun, .drawing-sun img {
  -webkit-animation: rotating 4s linear infinite;
  -moz-animation: rotating 4s linear infinite;
  -ms-animation: rotating 4s linear infinite;
  -o-animation: rotating 4s linear infinite;
  animation: rotating 4s linear infinite;
}


@-webkit-keyframes scaling {
  0% {
      -webkit-transform: scale(1) translateY(-50%);
      -o-transform: scale(1) translateY(-50%);
      transform: scale(1) translateY(-50%);
  }
  50% {
      -webkit-transform: scale(1.2) translateY(-50%);
      -o-transform: scale(1.2) translateY(-50%);
      transform: scale(1.2) translateY(-50%);
  }
  100% {
    -webkit-transform: scale(1) translateY(-50%);
    -o-transform: scale(1) translateY(-50%);
    transform: scale(1) translateY(-50%);
  }
}

@keyframes scaling {
  0% {
    -webkit-transform: scale(1) translateY(-50%);
    -o-transform: scale(1) translateY(-50%);
    transform: scale(1) translateY(-50%);
  }
  50% {
    -webkit-transform: scale(1.2) translateY(-50%);
    -o-transform: scale(1.2) translateY(-50%);
    transform: scale(1.2) translateY(-50%);
  }
  100% {
    -webkit-transform: scale(1) translateY(-50%);
    -o-transform: scale(1) translateY(-50%);
    transform: scale(1) translateY(-50%);
  }
}


.scale-smile {
  -webkit-animation: scaling 1.5s linear infinite;
  -moz-animation: scaling 1.5s linear infinite;
  -ms-animation: scaling 1.5s linear infinite;
  -o-animation: scaling 1.5s linear infinite;
  animation: scaling 1.5s linear infinite;
}


@keyframes slide {
  0% { right: 18;}
  50%{ right: 35px;}
  100%{ right: 18;}
}


.play-btn-icon {
  animation: slide 1.7s linear infinite;
}

@media screen and (min-width: 1200px) {
  .abs-img-green {
    transform: scale(0);
    transition: 2s;
    transform-origin : 50% 100%;
  }
}

.grow-dog {
  transform: scale(1);
}


.progress-loader {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 67%);
  text-align: center;
  top: 50%;

  svg {
    position: absolute;
    bottom: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.loader-text {
  text-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  font-size: 18px;
  line-height: normal;
  color: #ffffff;
  margin-bottom: 17px;
}

.loader-percentage {
  text-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  font-size: 42px;
  font-weight: bold;
  line-height: normal;
  color: #ffffff;
  padding-bottom: 64px;
}

.animate-progress {
}

@keyframes NLdTSdTJ_draw
{
  100%{stroke-dashoffset:0;}
}

.animate-progress-params {
  animation: NLdTSdTJ_draw 4000ms linear 0ms forwards;
}